* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    width: 100%;
}

.mainContainer {
    width: auto;
    height: 100%;
    /* border: 1px solid; */
    margin: 20px 40px;
    background-color: rgba(211, 211, 211, 0.562);
    border-radius: 20px;
    /* border-color: gray; */
    padding: 20px;
}

.containerFirst {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    justify-items: stretch;
    /* border: 1px solid black; */
    margin: 10px;
}

.mainImageContainer {
    width: auto;
    /* border: 1px solid black; */
    margin: 10px;
}

.mainImageContainer img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center center;
    border: 5px solid white;
    box-shadow: 0px 0px 20px 0px #00000099;
}

.userInfoContainer {
    width: calc(50% - 250px);
    height: auto;
    /* border: 1px solid black; */
    margin: 10px;
    padding: 10px;
}

.petTagsContainer {
    width: calc(60% - 250px);
    margin: 10px;
    /* border: 1px solid black; */
}

.interactionContainer {
    /* border: 1px solid black; */
    padding: 5px 0px;
}

.followChatNowBtn {
    /* border: 1px solid black; */
    padding: 5px 0px;
}

.shareContainer {
    /* border: 1px solid gray; */
    background-color: lightgray;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin: 10px;
    box-shadow: 0px 0px 20px 0px #ffffff;
}

#title {
    font-size: 20px;
    opacity: 70%;
}

#list-wrap li a i {
    font-size: 20px;
    font-weight: 500;
}

#calendarTitle {
    font-size: 20px;
    opacity: 70%;
}

.discriptionContainer {
    width: auto;
    height: auto;

    /* border: 1px solid black; */
}

.discriptionHeading {
    /* width: fit-content;
    position: relative;
    left: 45%;
    height: fit-content;
    background-color: #002169;
    padding: 5px 10px;
    margin-top: 10px; */
    color: rgba(0, 0, 0, 0.76);
}

.discriptionHeading span {
    font-size: 25px;
    font-weight: bold;
    text-decoration: underline;
    line-height: 60px;
}

.userServicesContainer {
    padding: 10px 20px;
    margin: 30px 10px;
    /* border: 1px solid gray; */
    background-color: lightgray;
    border-radius: 20px;
    box-shadow: 0px 0px 20px 0px #ffffff;
}

.reviewsContainer {
    width: auto;
    height: auto;
    /* border: 1px solid black; */
}

.reviewsHeading {
    /* width: fit-content;
    position: relative;
    left: 45%;
    height: fit-content;
    background-color: #002169;
    padding: 5px 10px;
    margin-top: 10px; */
    color: rgba(0, 0, 0, 0.76);
}

.reviewsHeading span {
    font-size: 25px;
    font-weight: bold;
    text-decoration: underline;
    line-height: 60px;
}

.reviewsPostContainer {
    margin: 10px;
}

@media only screen and (max-width: 600px) {
    .mainContainer {
        margin: 0px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 0%;
    }

    .containerFirst {
        width: 100%;
        flex-direction: column;
        /* align-items: center; */
    }

    .mainImageContainer {
        margin: 10px;
    }

    .mainImageContainer img {
        width: 150px;
        height: 150px;
    }

    .userInfoContainer {
        width: 100%;
        /* display: flex; */
        /* flex-direction: column; */
        /* align-items: center; */
    }

    .userInfo {
        position: absolute;
        top: 120px;
        left: 185px;
    }

    .petTagsContainer {
        width: 100%;
        margin: 0px;
    }

    .product__details-qty .add-btn {
        width: 100%;
    }

    .followChatNowBtn {
        display: flex;
        flex-wrap: nowrap;
        padding: 5px;
    }

    .product__size-wrap {
        flex-direction: column;
        margin: 15px;
    }

    .shareContainer {
        width: 100%;
        padding: 10px 15px;
        border-radius: 10px;
        margin: 0px;
        /* flex-direction: column; */
        align-items: stretch;
        justify-content: stretch;
    }

    .discriptionContainer {
        width: 100%;
    }

    .userServicesContainer {
        padding: 5px 15px;
        margin: 15px 0px;
    }

    .discriptionHeading {
        left: 35%;
    }

    .discriptionHeading span {
        font-size: 25px;
        font-weight: bold;
        text-decoration: underline;
        line-height: 60px;
    }

    .reviewsContainer {
        width: 100%;
    }

    .reviewsHeading {
        left: 35%;
    }

    .reviewsHeading span {
        font-size: 25px;
        font-weight: bold;
        text-decoration: underline;
        line-height: 60px;
    }

    .reviewsMainContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .reviewsMainContainerOne {
        margin: 0px 0px;
        width: 100%;
        /* border: 1px solid black; */
    }

    .reviewsMainContainerTwo {
        margin: 0px 0px;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .mainContainer {
        margin: 0px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 0%;
    }

    .containerFirst {
        width: 100%;
        flex-direction: column;
        /* align-items: center; */
    }

    .mainImageContainer {
        margin: 10px;
    }

    .mainImageContainer img {
        width: 150px;
        height: 150px;
    }

    .userInfoContainer {
        width: 100%;
        /* display: flex; */
        /* flex-direction: column; */
        /* align-items: center; */
    }

    .userInfo {
        position: absolute;
        top: 120px;
        left: 200px;
    }

    .petTagsContainer {
        width: 100%;
        margin: 0px;
    }

    .product__details-qty .add-btn {
        width: 100%;
    }

    .followChatNowBtn {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: center;
        padding: 5px;
    }

    .product__size-wrap {
        flex-direction: column;
        margin: 15px;
    }

    .shareContainer {
        width: 100%;
        padding: 10px 15px;
        border-radius: 10px;
        margin: 0px;
        /* flex-direction: column; */
        align-items: center;
        justify-content: space-between;
    }

    .discriptionContainer {
        width: 100%;
    }

    .userServicesContainer {
        padding: 5px 15px;
        margin: 15px 0px;
    }

    .discriptionHeading {
        left: 35%;
    }

    .discriptionHeading span {
        font-size: 25px;
        font-weight: bold;
        text-decoration: underline;
        line-height: 60px;
    }

    .reviewsContainer {
        width: 100%;
    }

    .reviewsHeading {
        left: 35%;
    }

    .reviewsHeading span {
        font-size: 25px;
        font-weight: bold;
        text-decoration: underline;
        line-height: 60px;
    }

    .reviewsMainContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .reviewsMainContainerOne {
        margin: 0px 0px;
        width: 100%;
        /* border: 1px solid black; */
    }

    .reviewsMainContainerTwo {
        margin: 0px 0px;
        width: 100%;
    }
}

.reviewsMainContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
}

.reviewsMainContainerOne {
    /* margin: 0px 10px; */
    width: 100%;
}

.reviewsMainContainerTwo {
    margin: 0px 10px;
    width: 100%;
}