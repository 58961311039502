* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    width: 100%;
}

.main_Container {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    align-items: stretch;
}

.section_One {
    width: 20%;
    height: auto;
    position: relative;
    margin: 5px 5px 5px 5px;
    background-color: #F9F9F9;
    border-radius: 10px;
    overflow: hidden;
    padding: 5px 10px;
}


.pet-png img {
    position: absolute;
    max-width: 100%;
    height: auto;
}

.pet-png img:first-child {
    top: 0;
    left: 0;
}

.pet-png img:last-child {
    bottom: 0;
    right: 0;
}

.section_One_Main {
    position: relative;
}

.main_Heading {
    text-align: center;
}

.main_title {
    color: black;
    font-weight: bold;
}

.total_Post_Container {
    text-align: center;
}

.section_One_Container_Two {
    padding: 5px;
}

.section_Two {
    width: 80%;
    height: auto;
    position: relative;
    margin: 5px 5px 5px 5px;
    padding: 10px;
    border-radius: 10px;
    background-color: #F9F9F9;
}

.post_Create_Section {
    position: absolute;
    right: 0;
    z-index: 1;
    margin: 10px;
}

.post_list {
    border-radius: 10px;
    position: relative;
}

.post_tag {
    padding: 1px;
}

.post_Rows {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    margin: 5px;
}

.main_Post-Container {
    background: var(--tg-color-white-default);
    border-radius: 20px;
    overflow: hidden;
    margin: 20px;
    padding: 30px 30px;
    width: 300px;
    cursor: pointer;
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
}

.main_Post {
    padding: 1px;
}
.main_title-Text {
    color: black;
    font-size: x-large;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; /* Add ellipsis (...) for overflow text */
}

.tag_Main-Container {
    padding: 1px;
}

@media (max-width: 575.98px) {
    .main_Container {
        flex-direction: column;
    }

    .section_One {
        width: 100%;
    }

    .section_Two {
        width: 100%;
    }
}

@media (max-width: 1199.98px) {
    .main_Post-Container {
        padding: 25px 20px;
        width: 300px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .main_Post-Container {
        padding: 35px 30px;
        width: 300px;
    }
}

.main_Post-Container .title {
    margin-bottom: 6px;
    font-size: 18px;
    font-weight: 400;
}

@media (max-width: 1199.98px) {
    .main_Post-Container .title {
        font-size: 16px;


    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .main_Post-Container .title {
        font-size: 26px;
    }
}

.main_Post-Container p {
    margin-bottom: 20px;
    font-weight: 500;
    color: #894B8D;
}