.share-button-container {
    position: relative;
}

.share-button {
    background-color: transparent;
    color: black;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    outline: none;
}

.share-options {
    position: absolute;
    /* top: 50px;
    left: 0; */
    top: -50px;
    left: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.share-options a {
    color: #333;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;
}

@media (min-width: 768px) {
    .share-button-container {
        display: none;
    }
}